require(['jquery','snap'], function(jQuery, Snap) {
	var svgElem = jQuery('.error-svg');
	var s = Snap('.error-svg');
	Snap.load(window.cfBase + 'images/icons/errors/Not-found.svg', function(f) {
		s.attr({
			'width': 718,
			'height': 370,
			'viewBox': '0 0 718 370'
		});
		s.append(f);

		//Earthquake
		var city = s.select('#Cityscape');
		var y = 0;
		function earthQuake() {
			y += 2;
			if(y >= 300) {
				city.stop();
				return;
			}
			city.animate({
				'transform': 'translate(-5, ' + y + ')'
			}, 45, function() {
				city.animate({
					'transform': 'translate(5, ' + y + ')'
				}, 45, earthQuake);
			});
		}

		//Paper animation
		var paper = s.select('#Paper');
		function paperDriftAnimation() {
			paper.stop().animate({
				'transform': 'translate(-25, 25)'
			}, 2000, mina.easeinout,
			function() {
				paper.animate({
					'transform': 'translate(25, 50)'
				}, 2000, mina.easeinout,
				function() {
					paper.animate({
						'transform': 'translate(-25, 75)'
					}, 2000, mina.easeinout,
					function() {
						paper.animate({
							'transform': 'translate(0, 100)'
						}, 2000, mina.easeinout, function() {
							setTimeout(earthQuake, 200);
						});
					});
				});
			});
		}

		paperDriftAnimation();
	});
});
define("vendor/errors/not_found", function(){});

